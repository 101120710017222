








































import {Vue, Component} from "vue-property-decorator";
import AccountListType from "./AccountList";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import ZoomPage from "../../../impView/PageSafety";
import AccountListItem from "@/views/Money/AccountList/components/Item/index.vue";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import {getAccountList} from "@/views/Money/Server";
import {BodyType} from "@/Api/Money/indexType";
import {filterDefaultList} from "@/views/Money/AccountList/util";
import ToPathAndRefresh from "@/components/ShareComponent/ToPathAndRefresh/index.vue";

@Component({name: "AccountList",components:{ HeadTop,AccountListItem,PullDownUpList,ListBottom,ToPathAndRefresh }})
export default class AccountList extends ZoomPage implements AccountListType{
    addIcon = require("@/assets/icon/Basics/Add.png")
    PullDown = false
    UpDownBool = false
    StopUp = false
    upData:BodyType = {
        pageNo:1,
        pageSize:15
    }
    List:any[] = []
    defaultData = {}
    defaultShow = false

    mounted(){
        this.loadingShow = false
    }

    activated(){
        this.handleUpData(true)
    }

    handleRefresh(){
        this.upData.pageNo = 1
        this.UpDownBool = true
        this.handleUpData(true)
    }

    handlePullDown(){
        this.upData.pageNo = 1
        this.PullDown = true
        this.handleUpData(true)
    }

    handleUpDown(){
        if (this.StopUp)return;
        this.upData.pageNo += 1
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        let { List } = this
        getAccountList(this.upData).then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < this.upData.pageSize )this.StopUp = true;
            if ( bool ){
                let { defaultData,List:newList } = filterDefaultList(res)
                this.List = newList;
                this.defaultData = defaultData;
                this.defaultShow = JSON.stringify(defaultData) == "{}" && true || false
            } else {
                let { defaultData,List:newList } = filterDefaultList(List.concat(res) as [])
                this.List = newList;
                this.defaultData = defaultData;
                this.defaultShow = JSON.stringify(defaultData) == "{}" && true || false
            }
        })
    }
}
