





import {Vue, Component, Emit, Prop} from "vue-property-decorator";
import ZoomPage from "@/impView/PageSafety";
import RefreshComType from "@/views/Other/GuessBox/components/Refresh/indexType";

@Component({name: "ToPathAndRefresh"})
export default class ToPathAndRefresh extends ZoomPage implements RefreshComType{
    show = false

    handleClick(){
        this.show = true
        let time = setTimeout(()=>{
            this.show = false
            clearTimeout(time)
        },1000);
        if ( this.getRefresh ) this.pullRefresh();
        else this.$router.push({ path:this.getPath });
    }

    @Prop(String)
    icon!:string
    get getIcon(){
        return this.icon || require("@/assets/icon/Basics/Add.png")
    }

    @Prop(String)
    path!:string
    get getPath(){ return this.path || '/' }

    @Prop(Boolean)
    refresh!:boolean
    get getRefresh(){
        return this.refresh || false
    }

    @Emit("pullRefresh")
    pullRefresh(){
        return
    }
}
