










































import {Vue, Component, Prop, Emit, Watch} from "vue-property-decorator";
import DefaultAccountType from "./indexType";
import moment from "moment";
import {setUpDateAccount} from "@/views/Money/Server";
import {Toast} from "vant";
import {removeAccount} from "@/views/Money/AccountList/Server";
import {AccountListStore} from "@/views/Money/AccountList/Store";

@Component({name: "DefaultAccount"})
export default class DefaultAccount extends Vue implements DefaultAccountType{
    wxIcon = require("@/views/Money/Img/wx.png")
    zfbIcon = require("@/views/Money/Img/zfb.png")

    activated(){
        //
    }

    handleClickSelect(){
        if ( this.getState != 'default' ){
            AccountListStore.SetAccountListSelect(this.getData)
            this.$router.go(-1)
        }
    }

    handleClick(state:number){
        switch ( state ) {
            case 1:
                // eslint-disable-next-line
                // @ts-ignore
                setUpDateAccount({ id:this.getData.id,defaultType:0 }).then(res=>{
                    Toast("设置成功")
                    this.$router.go(-1)
                })
                return
            case 2:
                this.$router.push({
                    name:"addAccount",
                    query:{
                        state:"update",
                        data:JSON.stringify(this.getData || {})
                    }
                })
                return
            case 3:
                removeAccount(this.getData.id).then(res=>{
                    Toast("删除成功")
                    this.pullRefresh()
                })
                return
        }
    }

    get getTime(){
        return this.getData && moment(this.getData.createTime).format("YYYY-MM-DD HH:mm")
    }

    get defaultShow(){
        if ( this.getData && JSON.stringify(this.getData) == "{}" ){
            return false
        }else{
            return true
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }

    @Prop(Number)
    index!:number
    get getIndex(){return this.index}

    @Prop(String)
    state!:string
    get getState(){ return this.state }

    @Emit("pullRefresh")
    pullRefresh(data = ""){
        return data
    }
}
